<template>
    <div>
        <div class="creator_detail_title">
            {{ $t('creator') }}
        </div>
        <div class="creator_detail_backImg">
            <img :src="returnBackground()" width="1200" height="310" alt="">
        </div>
        <div class="creator_detail_info_box">
            <div class="creator_detail_profileImg">
                <div class="profileImg">
                    <img :src="returnProfile()" width="180" height="180" alt="">
                </div>
                <div class="user_util_box">
                    <div class="user_user_like_wrap">
                        <div class="user_like_box">
                            <div class="like_img_wrap">
                                <div class="like_img cursor_none"></div>
                                <span class="like_count">{{ numFormat(memberData.likeCnt) }}</span>
                            </div>
                        </div>
                        <div class="right_line"></div>
                        <div class="user_item_count_box">
                            <span class="item_text">{{ $t('en_item') }}</span>
                            <span class="item_count">{{ numFormat(memberData.itemCnt) }}</span>
                        </div>
                    </div>
                    <div class="user_share_box">
                        <share-component class="in_block" :member-data="memberData"></share-component>
                    </div>
                </div>
            </div>
            <div class="creator_detail_nick">
                <span class="creator_nick">{{ memberData.mb_nick }}</span>
                <div class="creator_detail_badge_box curator" v-if="memberData.g_idx === 2">
                    <span>{{ $t('en_curator') }}</span>
                </div>
                <div class="creator_detail_badge_box special" v-if="memberData.mb_premium === 1">
                    <span>{{ $t('en_special') }}</span>
                </div>
                <div class="creator_btn_box">
                    <button class="creator_btn curator" v-if="returnCreatorType() === 'curator'"
                            @click="openModal('curation-history-modal')">
                        <span>{{ $t('curation_history') }}</span>
                    </button>
                    <button class="creator_btn curator" v-else-if="returnCreatorType() === 'apply'"
                            @click="movePage('/curationapply')">
                        <span>{{ $t('curator_ready') }}</span>
                    </button>
                    <button class="creator_btn curator" v-else-if="curatorStatus" @click="movePage('/curationapply')">
                        <span>{{ $t('curator_apply') }}</span>
                    </button>

                    <button class="creator_btn request" @click="openModal('profile-setting-modal')">
                        <span>{{ $t('profile_setting') }}</span>
                    </button>
                </div>
            </div>
            <div class="creator_detail_url">
                <div class="detail_url" v-if="!isEmptyData(memberData.mb_sns_1)">
                    <a :href="returnUrl(memberData.mb_sns_1)" target="_blank">{{ returnUrl(memberData.mb_sns_1) }}</a>
                </div>
                <div class="detail_url" v-if="!isEmptyData(memberData.mb_sns_2)">
                    <a :href="returnUrl(memberData.mb_sns_2)" target="_blank">{{ returnUrl(memberData.mb_sns_2) }}</a>
                </div>
                <div class="detail_url" v-if="!isEmptyData(memberData.mb_sns_3)">
                    <a :href="returnUrl(memberData.mb_sns_3)" target="_blank">{{ returnUrl(memberData.mb_sns_3) }}</a>
                </div>
            </div>

            <div class="create_detail_intro_wrap">
                <input type="checkbox" id="expanded" v-model="onceMore">
                <div class="creator_detail_intro ql-editor" v-html="replaceBr(memberData.mb_introduce)"></div>
                <!--   <div class="intro_more" :class="{'off':!onceMore}">
                       <label for="expanded" role="button" class="content_g_button" :class="{'off':!onceMore}" v-if="!isEmptyData(memberData.mb_introduce)"></label>
                   </div>-->
            </div>
        </div>
        <profile-setting-modal></profile-setting-modal>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import ProfileSettingModal from "@/components/modal/ProfileSettingModal";
import LikeComponent from "@/components/like/LikeComponent";
import ShareComponent from "@/components/share/ShareComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "MemberDetailMyLayout",
    mixins: [imageOption, alertMixins,imageResizeMixins],
    components: {
        LikeComponent,
        ShareComponent,
        ProfileSettingModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            onceMore: false,
            curatorStatus: false
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setMetaTitle();
    },
    mounted() {
        this.setMoreContent();
        this.checkRequestCurator();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        setMetaTitle(){
          if (!util.isEmpty(this.memberData) && this.memberData.hasOwnProperty('mb_nick')) {
            EventBus.$emit('setMetaTitle', this.memberData.mb_nick);
          }
        },
        movePage(url) {
            this.$router.push(url);
        },

        openModal(name) {
            this.$modal.show(name);
        },
        numFormat(num) {
            if (util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnCreatorType() {
            if (this.memberData.g_idx === 2) {
                return 'curator'
            } else if (!this.isEmptyData(this.memberData.c_idx)) {
                return 'apply'
            }
            return 'normal'
        },
        setCuratorBtnText() {
            if (this.memberData.g_idx === 2) {
                return 'curator'
                return this.$t('curation_history')
            } else if (!this.isEmptyData(this.memberData.c_idx)) {
                return 'apply'
                return this.$t('curator_ready')
            }
            return 'none'
            return this.$t('curator_apply')
        },
        returnBackground() {
            if (util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.ProfileBack)) {
                return "";
            }
            return `${this.memberData.Files.ProfileBack[0].org_url}?${this.setImageOptions(1200, 310,  this.returnExtension(this.memberData.Files.ProfileBack[0].org_url))}`;
            // return `${this.memberData.Files.ProfileBack[0].org_url}`;
        },
        returnProfile() {
            if (util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.Profile)) {
                return "";
            }
            return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(180, 180, 'png')}`;
        },
        returnUrl(url) {
            if (util.isEmpty(url)) {
                return "";
            }
            let expUrl = /^http[s]?\:\/\//i;
            if (!expUrl.test(url)) {
                return `http://${url}`;
            }
            return url;
        },
        setMoreContent() {
            if (util.isEmpty(this.memberData.mb_introduce)) {
                return false;
            }
            const ps = document.querySelectorAll('.creator_detail_intro');
            if (ps.length === 0) {
                setTimeout(() => {
                    this.setMoreContent();
                }, 200)
                return false;
            }
            let $this = this;
            ps.forEach(p => {
                p.style.lineHeight = '22.4px';
            })
            this.onceObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    let divHeight = entry.target.scrollHeight
                    let lineHeight = parseInt(entry.target.style.lineHeight);
                    let lines = Math.floor(divHeight / lineHeight);
                    if (!$this.onceMore && lines > 5) {
                        entry.target.classList['add']('off');
                    }
                }
            });

            ps.forEach(p => {
                this.onceObserver.observe(p);
            });
            return true;
        },
        checkRequestCurator() {
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$curation.checkApplyCurator().then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false)
                    if (res.Info.type === 1 || res.Info.type === 99) {
                        this.curatorStatus = true;
                        //this.errorAlert(this.$t('curator_application_err4'))
                    } else {
                        this.curatorStatus = false;
                        //this.$router.push('/curationapply')
                    }
                })
            } catch (e) {
                console.log(e);

            }
        },
    },
    watch: {
        'memberData': {
            deep: true,
            immediate: true,
            handler: function (val, oldVal) {
                this.setMoreContent();
                this.setMetaTitle();
            }
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <modal name="profile-setting-modal" class="profile-setting-modal"
           :width="`1240px`" :height="`80%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="profile_setting_wrap">
            <div class="ps_header">
                <h3>{{$t('profile_setting')}}</h3>
                <div class="back_btn" @click="modalClose()"></div>
            </div>
            <member-write-component :member-type="'profileSetting'" :memberKey="UserInfo.mb_no"></member-write-component>
        </div>
    </modal>
</template>

<script>
import {mapState} from "vuex";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "ProfileSettingModal",
    mixins: [],
    components: {
        MemberWriteComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            url: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('profile-setting-modal');
        },
        returnUrl(){
            return this.url
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
